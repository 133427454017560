// 固定参数配置文件

// 个人证件类型
export const cardType = [
	{
		value: '01',
		label: '身份证',
	},
	{
		value: '02',
		label: '护照',
	},
	{
		value: '03',
		label: '军官证',
	},
	{
		value: '05',
		label: '港澳回乡证',
	},
	{
		value: '07',
		label: '台胞证',
	},
];

// 团体证件类型
export const groupCardType = [
	{
		value: '3',
		label: '统一社会信用代码',
	},
	// {
	//     value: '1',
	//     label: '组织机构代码'
	// }
];

// 证件类型映射关系
export const cardTypeMap = {
	'01': '身份证',
	'02': '护照',
	'03': '军官证',
	'05': '港澳回乡证',
	'07': '台胞证',
	'3': '统一社会信用代码',
};

// 证件类型映射关系
export const cardTypeMap2 = {
	身份证: '01',
	护照: '02',
	军官证: '03',
	港澳回乡证: '05',
	台胞证: '07',
	统一社会信用代码: '3',
};

// 性别
export const sexs = [
	{
		value: '男',
		label: '男',
	},
	{
		value: '女',
		label: '女',
	},
];

// 订单状态
export const billState = {
	1: '待核保',
	2: '待支付',
	3: '已支付',
	4: '已承保',
	5: '已退保',
	7: '正在出单',
	9: '已失效',
	10:'退保申请中'
};

// 投保人类型
export const insureUserType = [
	{
		name: '1',
		value: '个人',
	},
	{
		name: '2',
		value: '企业',
	},
];

export const insureUserTypeMap = {
	个人: '1',
	企业: '2',
};

// 支付状态
export const payState = [
	{ name: '未支付', value: 0 },
	{ name: '已支付', value: 1 },
	{ name: '已退款', value: 2 },
	{ name: '退款成功', value: 3 },
	{ name: '退款失败', value: 4 },
];

// 支付状态字典
export const payStateDict = {
	0: '未支付',
	1: '已支付',
	2: '已退款',
	3: '退款成功',
	4: '退款失败',
};

// 与投保人关系,值还没确定下来
export const relationList = [
	{ label: '本人', value: '01' },
	{ label: '其他', value: '99' },
	{ label: '配偶', value: '02' },
	{ label: '子女', value: '03' },
	{ label: '父母', value: '04' },
];
export const relationMap = {
	本人: '01',
	配偶: '02',
	子女: '03',
	父母: '04',
	其他: '99',
};
