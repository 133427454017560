// 正则表达式
class Regular {
	// 手机号码
	phone = /^1[3,4,5,6,7,8,9]\d{9}$/;

	// 电话号码
	tel = /^([0-9]{3,4}-)?[0-9]{7,8}$/;

	// 身份证号码
	idcard = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/;

	// 护照,平安的只支持7-9位
	passport = /^([a-zA-z]|[0-9]){7,9}$/;

	// 军人证
	army = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/;

	// 港澳回乡证
	hkCard = /^([A-Z]\d{6,10}(\(\w{1}\))?)$/;

	// 台胞证
	twCard = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/;

	// 邮箱
	email = /[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}/;

	// 匹配连续出现7次的数字
	repeatReg7 = /(\d)\1{6,}/;

	// 匹配连续7个数字
	sequentialReg7 = /0123456|1234567|2345678|3456789|4567890|0987654|9876543|8765432|7654321|6543210/;

	// 姓名-判断只能输入中文或英文 以及·
	name1=/^[a-zA-Z·]+$|^[\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF·]+$/
	
	//姓名-至少2个中文或4个英文字母
	name2=/[a-zA-Z]{4,}|[\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]{2,}/
}

export default new Regular();
